<header class="header-area v2 angle-1" id="home-area">
  <div class="container">
    <div class="row row-flex">
      <div class="col-xs-12 col-md-7">
        <div class="text-box text-white">
          <h2 class="title">
            The best <br />
            Cow Ghee for <br />
            your Costly Health.
          </h2>
          <p>
            <span [innerHtml]="products.description"></span>
          </p>
          <div class="space-30"></div>
          <a href="#" class="bttn-4">Discover Now</a>
        </div>
      </div>
      <div
        class="col-xs-12 col-md-5 text-right section-bg"
        style="
          background-image: url('assets/images/circle-bg-2.png');
          background-size: 100% auto;
        "
      >
        <div class="space-60 hidden-md hidden-lg"></div>
        <div class="product-single-slider wow zoomIn">
          <ng-container *ngFor="let car of products.topBanners">
            <div class="item">
              <img [src]="car" alt="" />
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="topshapewrap">
    <div class="topshape"></div>
  </div>
</header>

<!-- Header-Area-End -->
<section
  class="intro-area v2 section-padding circle-bg relative-box"
  style="
    background-image: url('assets/images/banifits.png'),
      url('assets/images/wtach-section-1.png'),
      url('assets/images/circle-bg.png');
    background-position: right 58vw top 5vh, right 58vw bottom 10vh,
      right 60vw center;
  "
  id="about-area"
>
  <div class="container">
    <div class="row row-flex">
      <div class="col-xs-12 col-md-6 col-md-offset-6">
        <div class="text-box">
          <h2 class="title">Book your ghee from anywhere</h2>
          <p>
            Book your premium-quality ghee effortlessly from anywhere in the
            world. Experience the rich taste and health benefits of the finest
            ghee. Delivered straight to your doorstep for your convenience!
          </p>
          <div class="space-30"></div>
          <a href="#" class="bttn-5">Discover Now</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section
  class="intro-area v2 section-padding circle-bg"
  style="
    background-image: url('assets/images/wtach-section-2.png'),
      url('assets/images/Pro-2.jpg'), url('assets/images/circle-bg.png');
    background-position: left 58vw bottom 17vh, left 75vw top 10vh,
      left 56vw center;
  "
>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="text-box">
          <div class="box-icon v2">
            <i class="fa fa-dribbble"></i>
          </div>
          <h2 class="title">World best health resistant ghee</h2>
          <p>
            The world’s best health-resistant ghee is known for its exceptional
            quality, purity, and numerous health benefits. Packed with essential
            nutrients, it enhances immunity, aids digestion, and is rich in
            antioxidants, making it a must-have for a healthy lifestyle.
          </p>
          <div class="space-30"></div>
          <a href="#" class="bttn-5">Discover Now</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="feature-area v2 section-padding" id="service-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="text-box text-center">
          <div class="box-icon v2">
            <i class="fa fa-gg" aria-hidden="true"></i>
          </div>
          <h2 class="title">Much more exclusive features</h2>
          <div class="space-60"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let feature of products.benifitFeatures">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <div class="feature-box v2 wow fadeInLeft" data-wow-delay="0.3s">
            <div class="box-icon">
              <i class="fa fa-pencil"></i>
            </div>
            <h3 class="title">{{ feature.title }}</h3>
            <p class="desc">
              {{ feature.desc }}
            </p>
          </div>
        </div>
      </ng-container>

      <!-- <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="feature-box v3 wow fadeInLeft" data-wow-delay="0.5s">
          <div class="box-icon">
            <i class="fa fa-cloud"></i>
          </div>
          <h3 class="title">Cloud Service</h3>
          <p class="desc">In at iaculis lorem ipsum. Praesent tempor dictum</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="feature-box v4 wow fadeInLeft" data-wow-delay="0.7s">
          <div class="box-icon">
            <i class="fa fa-crop"></i>
          </div>
          <h3 class="title">Vector Editing</h3>
          <p class="desc">In at iaculis lorem ipsum. Praesent tempor dictum</p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="feature-box v5 wow fadeInLeft" data-wow-delay="0.9s">
          <div class="box-icon">
            <i class="fa fa-database"></i>
          </div>
          <h3 class="title">Export Presets</h3>
          <p class="desc">In at iaculis lorem ipsum. Praesent tempor dictum</p>
        </div>
      </div> -->
    </div>
  </div>
</section>

<section id="product-area" class="product-area v2 angle-3">
  <div class="container">
    <div class="product-slider-2">
      <div *ngFor="let item of products.variants" class="row row-flex">
        <div class="col-xs-12 col-md-6">
          <div class="text-box text-white">
            <h2 class="title">{{ item.name }}</h2>
            <p class="desc">
              Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh
              mollis. Nulla porta risus id neque tempor, in efficitur justo
              imperdiet. Etiam a ex at ante tincidunt imperdiet.
            </p>
            <div class="space-30"></div>
            <a (click)="addToCart(item)" class="bttn-4">Buy It Now</a>
            <div class="space-30"></div>
            <div class="title"><small>Only</small> ₹ {{ item.price }}</div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5 col-md-offset-1">
          <figure class="single-image">
            <img [src]="item.images[0]" alt="" />
          </figure>
        </div>
      </div>
      <!-- <div class="row row-flex">
        <div class="col-xs-12 col-md-6">
          <div class="text-box text-white">
            <h2 class="title">Apple Watch 3 Special Edition</h2>
            <p class="desc">
              Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh
              mollis. Nulla porta risus id neque tempor, in efficitur justo
              imperdiet. Etiam a ex at ante tincidunt imperdiet.
            </p>
            <div class="space-30"></div>
            <a href="#" class="bttn-4">Buy It Now</a>
            <div class="space-30"></div>
            <div class="title"><small>Only</small> $99</div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5 col-md-offset-1">
          <figure class="single-image">
            <img src="assets/images/w2.png" alt="" />
          </figure>
        </div>
      </div>
      <div class="row row-flex">
        <div class="col-xs-12 col-md-6">
          <div class="text-box text-white">
            <h2 class="title">Apple Watch 3 Special Edition</h2>
            <p class="desc">
              Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh
              mollis. Nulla porta risus id neque tempor, in efficitur justo
              imperdiet. Etiam a ex at ante tincidunt imperdiet.
            </p>
            <div class="space-30"></div>
            <a href="#" class="bttn-4">Buy It Now</a>
            <div class="space-30"></div>
            <div class="title"><small>Only</small> $99</div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5 col-md-offset-1">
          <figure class="single-image">
            <img src="assets/images/w3.png" alt="" />
          </figure>
        </div>
      </div>
      <div class="row row-flex">
        <div class="col-xs-12 col-md-6">
          <div class="text-box text-white">
            <h2 class="title">Apple Watch 3 Special Edition</h2>
            <p class="desc">
              Vestibulum commodo sapien non elit porttitor, vitae volutpat nibh
              mollis. Nulla porta risus id neque tempor, in efficitur justo
              imperdiet. Etiam a ex at ante tincidunt imperdiet.
            </p>
            <div class="space-30"></div>
            <a href="#" class="bttn-4">Buy It Now</a>
            <div class="space-30"></div>
            <div class="title"><small>Only</small> $99</div>
          </div>
        </div>
        <div class="col-xs-12 col-md-5 col-md-offset-1">
          <figure class="single-image">
            <img src="assets/images/w4.png" alt="" />
          </figure>
        </div>
      </div> -->
    </div>
  </div>
  <div class="productShape1">
    <div class="productShape1inner"></div>
  </div>
  <div class="productShape2">
    <div class="productShape2inner"></div>
  </div>
</section>

<section class="section-padding-top produst-list-area v2" id="gallery-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="text-box text-center">
          <div class="box-icon v2">
            <i class="fa fa-codepen" aria-hidden="true"></i>
          </div>
          <h2 class="title">New arrivals exclusive clarified butter (ghee)</h2>
          <div class="space-60"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="product-slide v2">
        <ng-container *ngFor="let new of products.newArrivals">
          <div class="slide-item">
            <figure class="product-image">
              <img [src]="new.images[0]" alt="" />
            </figure>
            <div class="product-content">
              <h3 class="title">{{ new.name }}</h3>
              <h5 class="rate">₹{{ new.price }}</h5>
            </div>
            <a style="padding: 8px 16px" (click)="addToCart(new)" class="bttn-4"
              >Buy It Now</a
            >
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
<section class="section-padding testimonial-area v2" id="testimonial-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="text-box text-center">
          <div class="box-icon v2">
            <i class="fa fa-bolt" aria-hidden="true"></i>
          </div>
          <h2 class="title">Peoples always loves our products</h2>
          <div class="space-60"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="testimonails-2 paginate">
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-1.png" alt="" />
            </figure>
            <h4 class="name">Sayma Patel</h4>
            <div class="posi">Web Developer</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-2.png" alt="" />
            </figure>
            <h4 class="name">Nathu Lal</h4>
            <div class="posi">Graphic Design</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-3.png" alt="" />
            </figure>
            <h4 class="name">Girish Paliwal</h4>
            <div class="posi">Founder at Pixelite</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-4.png" alt="" />
            </figure>
            <h4 class="name">Shahriar Hossain</h4>
            <div class="posi">Founder at Pixelite</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-5.png" alt="" />
            </figure>
            <h4 class="name">JIGISHA</h4>
            <div class="posi">Head Of TTCM</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-6.png" alt="" />
            </figure>
            <h4 class="name">Arvind Paliwal</h4>
            <div class="posi">Founder at Pixelite</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-7.png" alt="" />
            </figure>
            <h4 class="name">Jorin Zuel</h4>
            <div class="posi">Founder at Pixelite</div>
          </div>
          <div class="single-testimonial">
            <p class="desc">
              I’ve read a ton of blog posts about how some startups hit this
              milestone with ease. They came up with an amazing idea that “just
              clicked”, made!.
            </p>
            <figure class="thumb">
              <img src="assets/images/testimonial-8.png" alt="" />
            </figure>
            <h4 class="name">Ashekur Rahman</h4>
            <div class="posi">Web Designer</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Price-Area-Start -->
<section class="price-area v2 angle-3 d-none" id="price-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="text-box text-center text-white">
          <div class="box-icon v2 white-box">
            <i class="fa fa-usd" aria-hidden="true"></i>
          </div>
          <h2 class="title">Peoples always loves our products</h2>
          <div class="space-60"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-md-4">
        <div class="single-price wow fadeInUp" data-wow-delay="0.3s">
          <div class="user">1 User</div>
          <h3 class="title">Regular Plan</h3>
          <div class="info">No extra hidden charge. All vat Included.</div>
          <h3 class="rate">$19.99</h3>
          <a href="" class="bttn-3">Get Started</a>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="single-price wow fadeInUp active" data-wow-delay="0.5s">
          <div class="user">1 User</div>
          <h3 class="title">Startup Plan</h3>
          <div class="info">No extra hidden charge. All vat Included.</div>
          <h3 class="rate">$59.99</h3>
          <a href="" class="bttn-3">Get Started</a>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="single-price wow fadeInUp" data-wow-delay="0.7s">
          <div class="user">1 User</div>
          <h3 class="title">Business Plan</h3>
          <div class="info">No extra hidden charge. All vat Included.</div>
          <h3 class="rate">$99.99</h3>
          <a href="" class="bttn-3">Get Started</a>
        </div>
      </div>
    </div>
  </div>
  <div class="productShape1">
    <div class="productShape1inner"></div>
  </div>
  <div class="productShape2">
    <div class="productShape2inner"></div>
  </div>
</section>
<!-- Price-Area-End -->
<section class="info-area v2 section-padding" id="contact-area">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="text-box text-center">
          <div class="box-icon v2">
            <i class="fa fa-map-signs" aria-hidden="true"></i>
          </div>
          <h2 class="title">Choose your nearest branch</h2>
          <div class="space-60"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <div class="info-box wow fadeIn" data-wow-delay="0.3s">
          <div class="box-image">
            <img src="assets/images/info-1.png" alt="" />
          </div>
          <div class="box-content">
            <h3 class="title">Nathdwara</h3>
            <p class="desc">2315 Al Shabab Street, Dubai,NY 10007, UAE</p>
            <h4 class="number">212-798-1369</h4>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="info-box wow fadeIn" data-wow-delay="0.5s">
          <div class="box-image">
            <img src="assets/images/info-2.png" alt="" />
          </div>
          <div class="box-content">
            <h3 class="title">Udaipur</h3>
            <p class="desc">2315 Small Street, London, NY 10007, UK</p>
            <h4 class="number">212-798-1369</h4>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="info-box wow fadeIn" data-wow-delay="0.7s">
          <div class="box-image">
            <img src="assets/images/info-3.png" alt="" />
          </div>
          <div class="box-content">
            <h3 class="title">Kankroli</h3>
            <p class="desc">2315 Small Street, New York, NY 10007, USA</p>
            <h4 class="number">212-798-1369</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Sponsor-Arae-Start -->
<div class="sponsor-area d-none">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h4>We are feauted on</h4>
      </div>
    </div>
    <div class="space-20"></div>
    <div class="row">
      <div class="col-xs-12 text-center">
        <div class="sponsors">
          <div class="item">
            <img src="assets/images/client-1.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-2.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-3.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-4.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-5.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-3.png" alt="" />
          </div>
          <div class="item">
            <img src="assets/images/client-2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
