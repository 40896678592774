import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartKey = 'cartItems'; // LocalStorage key for cart
  private cartItems: any[] = this.loadCartFromLocalStorage(); // Initialize from LocalStorage
  private cartCountSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(this.calculateCartCount());

  constructor() {}

  // Load cart from LocalStorage
  private loadCartFromLocalStorage(): any[] {
    const storedCart = localStorage.getItem(this.cartKey);
    return storedCart ? JSON.parse(storedCart) : [];
  }

  // Save cart to LocalStorage
  private saveCartToLocalStorage(): void {
    localStorage.setItem(this.cartKey, JSON.stringify(this.cartItems));
  }

  // Calculate total cart count
  private calculateCartCount(): number {
    return this.cartItems.reduce((count, item) => count + item.quantity, 0);
  }

  // Get current cart count as observable
  getCartCount() {
    return this.cartCountSubject.asObservable();
  }

  // Add product to cart
  addProductToCart(product: any) {
    const existingProduct = this.cartItems.find(
      (item) => item.id === product.id
    );

    if (existingProduct) {
      existingProduct.quantity++;
    } else {
      product.quantity = 1;
      this.cartItems.push(product);
    }

    this.saveCartToLocalStorage(); // Persist to LocalStorage
    this.updateCartCount();
  }

  // Update cart count and notify subscribers
  private updateCartCount() {
    const totalCount = this.calculateCartCount();
    this.cartCountSubject.next(totalCount);
  }

  // Get all cart items (for CartPage)
  getCartItems() {
    return this.cartItems;
  }

  // Remove product from cart
  removeProductFromCart(productId: number) {
    this.cartItems = this.cartItems.filter((item) => item.id !== productId);

    this.saveCartToLocalStorage(); // Persist to LocalStorage
    this.updateCartCount();
  }

  // Update product quantity in cart
  updateProductQuantity(productId: number, quantity: number) {
    const existingProduct = this.cartItems.find(
      (item) => item.id === productId
    );
    if (existingProduct) {
      existingProduct.quantity = quantity;

      this.saveCartToLocalStorage(); // Persist to LocalStorage
      this.updateCartCount();
    }
  }
}
