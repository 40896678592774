import { Component, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.scss'],
})
export class Dashboard1Component implements OnInit {
  activeSession = 5040;
  addTocart = 345;
  previousPeriod = 25;
  totalRevenue = 21050;

  recentActivity = [
    {
      time: '5 mins ago',
      author: 'Chris Gayle',
      action: 'liked an article',
      title: 'Mastering Cricket',
    },
    {
      time: '15 mins ago',
      author: 'Sophia Lee',
      action: 'liked an article',
      title: 'Mastering JavaScript',
    },
    {
      time: '20 mins ago',
      author: 'Michael Brown',
      action: 'started following',
      title: 'UX Design Trends',
    },
    {
      time: '25 mins ago',
      author: 'Rachel Green',
      action: 'posted a new blog',
      title: 'React vs Vue: Which One to Choose?',
    },
    {
      time: '30 mins ago',
      author: 'Daniel Wilson',
      action: 'updated his status',
      title: 'Working on an exciting project!',
    },
  ];

  jsonData = [
    { category: 'Smartphones', value: 450 },
    { category: 'Laptops', value: 350 },
    { category: 'Tablets', value: 280 },
    { category: 'Smartwatches', value: 190 },
    { category: 'Headphones', value: 410 },
    { category: 'Monitors', value: 320 },
    { category: 'Keyboards', value: 270 },
  ];

  chartLabels = this.jsonData.map((item) => item.category);
  chartDataValues = this.jsonData.map((item) => item.value);

  colors = [
    '#06f',
    '#8BC34A',
    '#daa520',
    '#B00020',
    '#E65100',
    '#006064',
    '#311B92',
  ];

  // ✅ Pie Chart
  public pieChartData: any[] = [
    { data: this.chartDataValues, backgroundColor: this.colors },
  ];
  public pieChartLabels: string[] = this.chartLabels;
  public pieChartType: string = 'pie';

  // ✅ Doughnut Chart

  // ✅ Bar Chart (Live Updating)
  public barChartData: any[] = [
    {
      data: this.chartDataValues,
      label: 'Sales (Units)',
      backgroundColor: this.colors,
    },
  ];
  public barChartLabels: string[] = this.chartLabels;
  public barChartType: string = 'bar';

  // ✅ Line Chart (Live Updating)
  public lineChartData: any[] = [
    {
      data: this.chartDataValues,
      label: 'Revenue ($K)',
      borderColor: '#d63384',
      backgroundColor: 'rgba(214, 51, 132, 0.5)',
      borderWidth: 3,
    },
  ];
  public lineChartLabels: string[] = this.chartLabels;
  public lineChartType: string = 'line';

  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false, // Ensure it respects the defined height
    scales: {
      xAxes: [{ ticks: { fontSize: 14 } }],
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
  };

  // ✅ Bubble Chart
  public bubbleChartData: any[] = [
    {
      data: this.jsonData.map((item, index) => ({
        x: index * 10 + 10,
        y: item.value / 10,
        r: item.value / 50,
      })),
      label: 'Product Performance',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: '#ff6384',
    },
  ];
  public bubbleChartType: string = 'bubble';

  // ✅ Radar Chart
  public radarChartData: any[] = [
    {
      data: this.chartDataValues,
      label: 'Customer Ratings',
      backgroundColor: 'rgba(54,162,235,0.4)',
      borderColor: '#36A2EB',
    },
  ];
  public radarChartLabels: string[] = this.chartLabels;
  public radarChartType: string = 'radar';

  // ✅ Polar Area Chart
  public polarChartData: any[] = [
    { data: this.chartDataValues, backgroundColor: this.colors },
  ];
  public polarChartLabels: string[] = this.chartLabels;
  public polarChartType: string = 'polarArea';

  constructor() {}

  // 🎯 Live Data Updates Every 2 Seconds
  ngOnInit() {
    setInterval(() => {
      this.updateLiveData();
      this.activeSession = this.activeSession + 1;
      this.totalRevenue = this.totalRevenue + 2 * 7;
    }, 5000);
  }

  updateLiveData() {
    this.jsonData.forEach((item) => {
      item.value = Math.floor(Math.random() * 500) + 100; // Random values between 100-600
    });

    this.chartDataValues = this.jsonData.map((item) => item.value);

    this.barChartData[0].data = [...this.chartDataValues];
    this.lineChartData[0].data = [...this.chartDataValues];

    this.bubbleChartData[0].data = this.jsonData.map((item, index) => ({
      x: index * 10 + 10,
      y: item.value / 10,
      r: item.value / 50,
    }));
  }

  public barChartType1: string = 'horizontalBar'; // Use 'horizontalBar' for v2

  public barChartOptions1: any = {
    responsive: true,
    maintainAspectRatio: false, // Allows custom height
    scales: {
      xAxes: [{ ticks: { beginAtZero: true } }], // Ensure bars grow left to right
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
    legend: { display: false }, // Hide legend
  };

  public barChartLabels1: string[] = [
    '2025',
    '2024',
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
  ];

  public barChartData1: any[] = [
    {
      data: [100, 175, 100, 125, 220, 190, 245, 150, 210],
      backgroundColor: [
        '#d63384',
        '#0d6efd',
        '#ffc107',
        '#198754',
        '#dc3545',
        '#d63384',
        '#06f',
        '#198754',
        '#0d6efd',
      ],
    },
  ];

  jsonData1 = [
    {
      label: 'Jan',
      smartphone: 120,
      tablet: 150,
      ledTv: 90,
      smartwatch: 100,
      headphones: 80,
    },
    {
      label: 'Feb',
      smartphone: 200,
      tablet: 250,
      ledTv: 180,
      smartwatch: 140,
      headphones: 120,
    },
    {
      label: 'Mar',
      smartphone: 170,
      tablet: 190,
      ledTv: 120,
      smartwatch: 160,
      headphones: 100,
    },
    {
      label: 'Apr',
      smartphone: 220,
      tablet: 300,
      ledTv: 250,
      smartwatch: 180,
      headphones: 150,
    },
    {
      label: 'May',
      smartphone: 300,
      tablet: 400,
      ledTv: 320,
      smartwatch: 220,
      headphones: 190,
    },
    {
      label: 'Jun',
      smartphone: 250,
      tablet: 350,
      ledTv: 280,
      smartwatch: 210,
      headphones: 170,
    },
  ];

  // Extract Labels & Data for Chart
  lineChartLabels1 = this.jsonData1.map((item) => item.label);
  public lineChartData1: any[] = [
    {
      data: this.jsonData1.map((item) => item.smartphone),
      label: 'Smartphone',
      borderColor: '#FF5733',
      fill: false,
    },
    {
      data: this.jsonData1.map((item) => item.tablet),
      label: 'Tablet',
      borderColor: '#33FF57',
      fill: false,
    },
    {
      data: this.jsonData1.map((item) => item.ledTv),
      label: 'LED TV',
      borderColor: '#3357FF',
      fill: false,
    },
    {
      data: this.jsonData1.map((item) => item.smartwatch),
      label: 'Smartwatch',
      borderColor: '#FFA500',
      fill: false,
    },
    {
      data: this.jsonData1.map((item) => item.headphones),
      label: 'Headphones',
      borderColor: '#8A2BE2',
      fill: false,
    },
  ];

  public lineChartOptions1: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{ ticks: { fontSize: 14 } }],
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
  };

  public lineChartType1 = 'line';

  //Doughtnut chart
  public doughnutChartLabels: string[] = [
    '📱 Smartphone',
    '🖥️ LED TV',
    '⌚ Smartwatch',
    '🎧 Headphones',
    '📟 Tablet',
  ];
  public doughnutChartData: any[] = [
    {
      data: [350, 250, 150, 200, 100],
      backgroundColor: ['#FF5733', '#33FF57', '#3357FF', '#FFA500', '#8A2BE2'],
    },
  ];

  public doughnutChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 60, // Adjust for better donut shape
    legend: { display: false }, // Hide legend
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let dataset = data.datasets[tooltipItem.datasetIndex];
          let currentValue = dataset.data[tooltipItem.index];
          let label = this.doughnutChartLabels[tooltipItem.index] || 'Unknown';
          return `${label}: ${currentValue} units`;
        },
      },
    },
  };

  public doughnutChartType = 'doughnut';
}
