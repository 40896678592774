<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="index.html" class="logo logo-dark">
          <span class="logo-lg">
            <img
              src="assets/images/logo-dark.png"
              alt="logo-dark"
              height="20"
            />
          </span>
        </a>

        <a href="index.html" class="logo logo-light">
          <span
            style="
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            "
            class="logo-lg"
          >
            <img
              style="height: 25px"
              src="https://shivamitcs.in/img/logo.png"
              alt="logo-light"
              height="20"
            />
            &nbsp; SHIVAM ITCS
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
        data-bs-toggle="collapse"
        data-bs-target="#topnav-menu-content"
      >
        <i class="ri-menu-2-line align-middle"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="Search..." />
          <span><i class="bx bx-search"></i></span>
        </div>
      </form>
    </div>

    <div class="d-flex">
      <div class="dropdown d-none d-sm-inline-block button-container">
        <button
          type="button"
          class="btn header-item waves-effect"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="bx bxs-pie-chart-alt-2"></i>
          Dashboard
        </button>
        <div class="click-hint">
          <span class="hint-text">Click to see other examples</span>
          <span class="hand-icon">👉</span>
        </div>
        <div class="dropdown-menu dropdown-menu-end">
          <!-- item-->
          <a (click)="navigateToDashboard(1)" class="dropdown-item notify-item">
            <span> Example 1 </span>
          </a>

          <!-- item-->
          <a (click)="navigateToDashboard(2)" class="dropdown-item notify-item">
            <span> Example 2 </span>
          </a>

          <!-- item-->
          <a (click)="navigateToDashboard(3)" class="dropdown-item notify-item">
            <span> Example 3 </span>
          </a>
        </div>
      </div>

      <!-- light dark -->
      <button
        type="button"
        class="btn header-item fs-4 rounded-end-0 d-none"
        id="light-dark-mode"
      >
        <i class="bx bxs-moon align-middle"></i>
      </button>

      <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bx bxs-bell-plus noti-icon"></i>

          <span class="badge rounded-pill text-bg-danger noti-dot">2</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px">
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle">
                    <i class="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div class="flex-1">
                  <p class="mb-0"><b class="mb-1">Your order is placed</b></p>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-danger rounded-circle">
                    <i class="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div class="flex-1">
                  <p class="mb-0"><b class="mb-1">New Message received</b></p>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="p-1">
            <div class="d-grid">
              <a
                href="javascript:void(0);"
                class="dropdown-item text-center notify-all text-primary"
              >
                View all <i class="fi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown notification-list d-inline-block user-dropdown">
        <button
          type="button"
          class="btn header-item waves-effect"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            class="rounded-circle header-profile-user"
            src="https://media.licdn.com/dms/image/v2/D5603AQG2ciUNN4obMA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1724237801119?e=1744848000&v=beta&t=aqCCi3KxTYFSF4BdkHHJDxPUZ6sqAeE91zW5ls-Ldxk"
            alt="Header Avatar"
          />
        </button>
        <div class="dropdown-menu dropdown-menu-end profile-dropdown">
          <!-- item-->
          <a class="dropdown-item" href="#"
            ><i class="bx bx-user-circle"></i> Profile</a
          >
          <a class="dropdown-item" href="#"
            ><i class="bx bx-wallet"></i> My Wallet</a
          >
          <a class="dropdown-item d-block" href="#"
            ><span class="badge text-bg-success float-end mt-1">11</span
            ><i class="bx bx-cog"></i>Settings</a
          >
          <a class="dropdown-item" href="#"
            ><i class="bx bx-lock"></i> Lock screen</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="#"
            ><i class="bx bx-log-in-circle"></i> Logout</a
          >
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
                        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
                            <i class="ri-settings-2-line"></i>
                        </button>
                    </div> -->
    </div>
  </div>
</header>
