import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import the components for the routes
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Dashboard1Component } from './pages/dashboard1/dashboard1.component';
import { Dashboard2Component } from './pages/dashboard2/dashboard2.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' }, // Redirect root to /products
  { path: 'dashboard', component: DashboardComponent }, // Product listing page
  { path: 'dashboard1', component: Dashboard1Component },
  { path: 'dashboard2', component: Dashboard2Component },

  { path: '**', redirectTo: '/dashboard' }, // Wildcard route redirects to /products
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })], // Configure the routes
  exports: [RouterModule], // Export the RouterModule for use in the app
})
export class AppRoutingModule {}
