<!-- start page title -->
<div style="margin-top: 10px" class="page-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-sm-flex align-items-center justify-content-between"
        >
          <h4 class="mb-sm-0">
            Dashboard - Example 2 with Material and Angular
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-xl-6">
        <div class="row dashboard-1">
          <div class="col-sm-6 col-xl-6">
            <div class="card blue bg-primary">
              <div class="card-heading p-4">
                <div class="d-flex">
                  <div class="flex-grow-1 mt-3">
                    <h5 class="font-size-17">Active Sessions</h5>
                  </div>
                  <div class="mini-stat-icon">
                    <i class="bx bxs-videos text-white bg-primary"></i>
                  </div>
                </div>
                <h3>{{ activeSession }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style="width: 75%"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  Previous period<span class="float-end">75%</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="card green bg-success">
              <div class="card-heading p-4">
                <div class="d-flex">
                  <div class="flex-grow-1 mt-3">
                    <h5 class="font-size-17">Total Revenue</h5>
                  </div>
                  <div class="mini-stat-icon">
                    <i class="bx bxs-dollar-circle bg-success text-white"></i>
                  </div>
                </div>
                <h3>{{ totalRevenue }}</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style="width: 88%"
                    aria-valuenow="88"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  Previous period<span class="float-end">88%</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="card yellow bg-warning">
              <div class="card-heading p-4">
                <div class="d-flex">
                  <div class="flex-grow-1 mt-3">
                    <h5 class="font-size-17">Average Price</h5>
                  </div>
                  <div class="mini-stat-icon">
                    <i class="bx bx-shopping-bag bg-warning text-white"></i>
                  </div>
                </div>
                <h3>447</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style="width: 68%"
                    aria-valuenow="68"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  Previous period<span class="float-end">68%</span>
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-xl-6">
            <div class="card red bg-danger">
              <div class="card-heading p-4">
                <div class="d-flex">
                  <div class="flex-grow-1 mt-3">
                    <h5 class="font-size-17">Add to Card</h5>
                  </div>
                  <div class="mini-stat-icon float-end">
                    <i class="bx bx-coin-stack bg-danger text-white"></i>
                  </div>
                </div>
                <h3>86%</h3>
                <div class="progress mt-4" style="height: 4px">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style="width: 82%"
                    aria-valuenow="82"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <p class="text-muted mt-2 mb-0">
                  Previous period<span class="float-end">81%</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Active Deals</h4>

            <canvas
              class="large-canvas"
              baseChart
              [datasets]="barChartData1"
              [labels]="barChartLabels1"
              [options]="barChartOptions1"
              [chartType]="barChartType1"
            ></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-5">
        <div style="min-height: 472px" class="card">
          <h4 class="mt-0 header-title mb-4 ms-3 mt-3">Sales Distribution</h4>
          <div class="card-body">
            <canvas
              baseChart
              [datasets]="doughnutChartData"
              [options]="doughnutChartOptions"
              [chartType]="doughnutChartType"
            ></canvas>
          </div>
        </div>
      </div>
      <div class="col-xl-7">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Recent Activity</h4>
            <ol class="activity-feed mb-0">
              <ng-container *ngFor="let item of recentActivity">
                <li class="feed-item">
                  <div class="feed-item-list">
                    <p class="text-muted mb-1">{{ item.time }}</p>
                    <p class="font-size-15 mt-0 mb-0">
                      {{ item.author }}
                      <b class="text-primary">{{ item.title }}</b>
                    </p>
                  </div>
                </li>
              </ng-container>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body" style="max-height: 500px; overflow: hidden">
            <h4 class="mt-0 header-title mb-4">Revenue Statistics</h4>
            <div style="height: 300px">
              <canvas
                baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [chartType]="lineChartType"
                [options]="lineChartOptions"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Product Performance</h4>
            <canvas
              baseChart
              [datasets]="bubbleChartData"
              [chartType]="bubbleChartType"
            ></canvas>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Sales Execution</h4>
            <canvas
              baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [chartType]="barChartType"
            ></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-6">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Active Dealers</h4>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Contact</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Philip Smead</td>
                    <td>
                      <span class="badge text-bg-success">Delivered</span>
                    </td>
                    <td>$9,420,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/users/user-2.jpg"
                          alt=""
                          class="avatar-sm rounded-circle me-2"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Brent Shipley</td>
                    <td><span class="badge text-bg-warning">Pending</span></td>
                    <td>$3,120,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/users/user-3.jpg"
                          alt=""
                          class="avatar-sm rounded-circle me-2"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Robert Sitton</td>
                    <td>
                      <span class="badge text-bg-success">Delivered</span>
                    </td>
                    <td>$6,360,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/users/user-4.jpg"
                          alt=""
                          class="avatar-sm rounded-circle me-2"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card m-b-30">
          <div class="card-body">
            <canvas
              baseChart
              [datasets]="radarChartData"
              [labels]="radarChartLabels"
              [chartType]="radarChartType"
            ></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Product Comparision</h4>
            <div class="chart-container">
              <canvas
                baseChart
                [datasets]="lineChartData1"
                [labels]="lineChartLabels1"
                [options]="lineChartOptions1"
                [chartType]="lineChartType1"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
