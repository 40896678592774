import { Component, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  cartCount: number = 0;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToDashboard(type) {
    switch (type) {
      case 1:
        this.router.navigate(['/dashboard']).then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        break;

      case 2:
        this.router.navigate(['/dashboard1']).then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        break;

      case 3:
        this.router.navigate(['/dashboard2']).then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        break;

      default:
        break;
    }
  }
}
