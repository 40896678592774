import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private products = {
    id: 301,
    name: 'Premium Clarified Cow Butter (Ghee)',
    topBanners: [
      'assets/images/w2.png',
      'assets/images/w3.png',
      'assets/images/w4.png',
    ],
    benifitSection: { title: 'Book your ghee from anywhere', description: '' },
    benifitFeatures: [
      {
        title: 'Supports Digestive Health',
        desc: 'Known for being gentle on the stomach, A2 Desi Cow Ghee can aid in digestion and improve gut health.',
      },
      {
        title: 'Boosts Immunity',
        desc: 'Rich in antioxidants and healthy fats, it helps boost immunity and provides the energy your body needs.',
      },
      {
        title: 'Good for Heart & Skin',
        desc: 'Ghee nourishes your body from the inside out, supporting heart health, promoting radiant skin, and even balancing mind and body',
      },
      {
        title: 'Naturally good',
        desc: 'Rich in antioxidants and healthy fats, it helps boost immunity and provides the energy your body needs.',
      },
    ],
    description:
      "Pure and aromatic clarified butter made from grass-fed cow's milk <br /> perfect for cooking and baking.",
    category: 'Food & Beverages',
    brand: "Nature's Essence",
    basePrice: 12.99,
    currency: 'Rs',
    variants: [
      {
        id: 'VAR-101',
        type: 'Weight',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        name: 'A2 desi cow ghee',
        price: 1800,
        stock: 50,
        attributes: {
          weight: '500g',
          packaging: 'Glass Jar',
          organic: true,
          shelfLife: '12 months',
        },
        images: ['assets/images/w1.png'],
      },
      {
        id: 'VAR-102',
        type: 'Weight',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        name: 'Gir cow ghee',
        price: 22.99,
        stock: 30,
        attributes: {
          weight: '1kg',
          packaging: 'Tin Can',
          organic: false,
          shelfLife: '18 months',
        },
        images: ['assets/images/w2.png'],
      },
      {
        id: 'VAR-103',
        type: 'Weight',
        name: 'Bilona ghee',
        price: 1100,
        stock: 100,
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        attributes: {
          weight: '250g',
          packaging: 'Plastic Jar',
          organic: true,
          shelfLife: '6 months',
        },
        images: ['assets/images/w3.png'],
      },
      {
        id: 'VAR-103',
        type: 'Weight',
        name: 'Makhania ghee',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        price: 900,
        stock: 100,
        attributes: {
          weight: '250g',
          packaging: 'Plastic Jar',
          organic: true,
          shelfLife: '6 months',
        },
        images: ['assets/images/w4.png'],
      },
    ],

    newArrivals: [
      {
        id: 'VAR-200',
        type: 'Weight',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        name: 'GIR cow ghee',
        price: 750,
        stock: 50,
        attributes: {
          weight: '500g',
          packaging: 'Glass Jar',
          organic: true,
          shelfLife: '12 months',
        },
        images: ['assets/images/w1.png'],
      },
      {
        id: 'VAR-201',
        type: 'Weight',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        name: '2kg - Bilona Cow ghee',
        price: 22.99,
        stock: 30,
        attributes: {
          weight: '1kg',
          packaging: 'Tin Can',
          organic: false,
          shelfLife: '18 months',
        },
        images: ['assets/images/w2.png'],
      },
      {
        id: 'VAR-202',
        type: 'Weight',
        name: 'Adhika Special',
        price: 800,
        stock: 100,
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        attributes: {
          weight: '250g',
          packaging: 'Plastic Jar',
          organic: true,
          shelfLife: '6 months',
        },
        images: ['assets/images/w3.png'],
      },
      {
        id: 'VAR-203',
        type: 'Weight',
        name: '250g - Deshi Cow ghee',
        description:
          "Pure and aromatic clarified butter made from grass-fed cow's milk, perfect for cooking and baking.",
        price: 1200,
        stock: 100,
        attributes: {
          weight: '250g',
          packaging: 'Plastic Jar',
          organic: true,
          shelfLife: '6 months',
        },
        images: ['assets/images/w4.png'],
      },
    ],
    rating: {
      average: 4.8,
      reviewsCount: 320,
    },
    features: [
      "Made from grass-fed cow's milk",
      'Rich aroma and flavor',
      'Ideal for high-heat cooking',
      'Free from artificial additives',
    ],
    usageTips: [
      'Use as a cooking oil for frying or sautéing.',
      'Spread on toast for a healthy alternative to butter.',
      'Add to coffee or tea for a creamy texture.',
    ],
    relatedProducts: [302, 303, 304],
    seller: {
      id: 601,
      name: 'Organic Pantry Co.',
      rating: 4.9,
    },
    certifications: [
      'USDA Organic',
      'Non-GMO',
      'Halal Certified',
      'Gluten-Free',
    ],
    storageInstructions:
      'Store in a cool, dry place away from direct sunlight. Refrigeration is not required.',
    nutritionInfo: {
      servingSize: '1 tbsp (14g)',
      calories: 120,
      fat: '14g',
      saturatedFat: '9g',
      cholesterol: '35mg',
      sodium: '0mg',
      carbohydrates: '0g',
      protein: '0g',
    },
  };

  constructor() {}

  getProducts() {
    return this.products;
  }

  getProductById(id: string) {
    // Convert the id from string to number for comparison if needed
    //return this.products.find((product) => product.id === Number(id));
  }
}
