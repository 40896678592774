import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit {
  products: any;
  filteredProducts: any[] = [];
  categories: string[] = [];
  selectedCategory: string | null = null;
  searchTerm: string = '';

  constructor(
    private dataService: DataService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.products = this.dataService.getProducts();
  }

  filterByCategory(category: string | null) {
    this.selectedCategory = category;
    this.applyFilters();
  }

  clearCategoryFilter() {
    this.selectedCategory = null;
    this.applyFilters(); // Reset filters to show all products
  }

  searchProducts() {
    this.applyFilters();
  }

  applyFilters() {
    this.filteredProducts = this.products.filter((product) => {
      const matchesCategory =
        !this.selectedCategory ||
        product.category.includes(this.selectedCategory);
      const matchesSearch = this.searchTerm
        ? product.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        : true;

      return matchesCategory && matchesSearch;
    });
  }

  addToCart(product: any) {
    this.cartService.addProductToCart(product);
  }
}
